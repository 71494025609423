@import "../../assets/styles/shared.scss";

.company {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #464646;
  color: white;
  padding-left: $padding-left;
  padding-right: $padding-right;
  padding-top: 50px;
  padding-bottom: 50px;

  &__message {
    display: flex;
    flex-direction: column;

    label {
      font-family: "Montserrat-SemiBold";
      font-size: 16px;
      line-height: 25px;
    }

    &__content,
    &__author {
      font-family: "Montserrat-Italic";
      font-size: 12px;
      line-height: 25px;
      margin-top: 0px;
    }

    &__author br {
      display: none;
    }
  }

  &__office {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    label {
      font-family: "Montserrat-SemiBold";
      font-size: 14px;
      line-height: 25px;
    }

    p {
      font-family: "Montserrat-Regular";
      font-size: 12px;
      line-height: 25px;
      text-align: right;
      margin-top: 0px;
    }
  }
}

@media (min-width: $device-width--md) {
  .company {
    height: 500px;
    flex-direction: row;
    padding-left: $padding-left--md;
    padding-right: $padding-right--md;
    padding-top: 130px;
    padding-bottom: 130px;

    &__message {
      width: 445px;

      label {
        font-size: 22px;
        line-height: 36px;
      }

      &__content,
      &__author {
        font-size: 18px;
        line-height: 36px;
      }

      &__author {
        @media (max-width: $device-width--lg - 1px) {
          display: none;
        }
      }
    }

    &__office {
      width: 160px;

      label {
        font-size: 22px;
        line-height: 36px;
      }

      p {
        font-size: 18px;
        line-height: 36px;
      }
    }
  }
}

@media (min-width: $device-width--lg) {
  .company {
    padding-left: $padding-left--lg;
    padding-right: $padding-right--lg;

    &__message {
      width: 605px;
    }
  }
}

@media (min-width: $device-width--xlg) {
  .company {
    padding-left: $padding-left--xlg;
    padding-right: $padding-right--xlg;

    &__message {
      width: 700px;
    }

    &__office {
      width: 400px;
    }
  }
}

@media (min-width: $device-width--xl) {
  .company {
    padding-left: $padding-left--xl;
    padding-right: $padding-right--xl;
  }
}

@media (min-width: $device-width--xxl) {
  .company {
    padding-left: $padding-left--xxl;
    padding-right: $padding-right--xxl;

    &__message {
      width: 775px;
    }
  }
}
