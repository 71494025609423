@import "../../assets/styles/shared.scss";

$padding-left--xlg: 90px;
$padding-right--xlg: 90px;

$padding-left--xl: 110px;
$padding-right--xl: 110px;

$padding-left--xxl: 190px;
$padding-right--xxl: 190px;

.page {
  display: flex;
  flex-direction: column;
  padding-left: $padding-left;
  padding-right: $padding-right;
  margin-top: 45px;
  margin-bottom: 50px;

  @media (min-width: $device-width--md) {
    padding-left: $padding-left--md;
    padding-right: $padding-right--md;
    flex-direction: row;
    margin-top: 165px;
    margin-bottom: 345px;
  }

  @media (min-width: $device-width--lg) {
    padding-left: $padding-left--lg;
    padding-right: $padding-right--lg;
    margin-top: 175px;
    margin-bottom: 440px;
  }

  @media (min-width: $device-width--xlg) {
    padding-left: $padding-left--xlg;
    padding-right: $padding-right--xlg;
    margin-top: 160px;
    margin-bottom: 420px;
  }

  @media (min-width: $device-width--xl) {
    padding-left: $padding-left--xl;
    padding-right: $padding-right--xl;
    margin-top: 150px;
    margin-bottom: 425px;
  }

  @media (min-width: $device-width--xxl) {
    padding-left: $padding-left--xxl;
    padding-right: $padding-right--xxl;
    margin-top: 165px;
    margin-bottom: 440px;
  }

  h1 {
    font-family: "Montserrat-SemiBold";
    margin-top: 0px;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 48px;
  }

  h3 {
    font-family: "Montserrat-SemiBold";
    margin-top: 0px;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 31px;
  }

  p {
    font-family: "LibreCaslonText-Regular";
    margin-top: 0px;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 0px;
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 55px;

    img {
      width: 170px;
    }

    @media (min-width: $device-width--md) {
      margin-top: auto;
      margin-right: 50px;
      margin-bottom: 0px;

      h1 {
        display: none;
      }
    }

    @media (min-width: $device-width--lg) {
      margin-right: 90px;

      img {
        width: 155px;
      }
    }

    @media (min-width: $device-width--xl) {
      margin-right: 60px;

      img {
        width: 200px;
      }
    }

    @media (min-width: $device-width--xxl) {
      margin-right: 95px;

      img {
        width: 165px;
      }
    }
  }

  &__content {
    h1 {
      display: none;
    }

    @media (min-width: $device-width--md) {
      h1 {
        display: block;
        margin-bottom: 70px;
      }

      h3 {
        margin-bottom: 45px;
      }
    }

    @media (min-width: $device-width--lg) {
      h1 {
        margin-bottom: 80px;
      }

      h3 {
        margin-bottom: 50px;
      }
    }

    @media (min-width: $device-width--xlg) {
      p {
        font-size: 18px;
        line-height: 36px;
      }
    }

    @media (min-width: $device-width--xl) {
      p {
        font-size: 19px;
        line-height: 38px;
      }
    }

    @media (min-width: $device-width--xxl) {
      p {
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
}
