@import "../../assets/styles/shared.scss";

.features {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &__content {
    overflow-y: hidden;
    transition: all 0.3s;

    &__opened {
      max-height: 1200px;
    }

    &__collapsed {
      max-height: 270px;
    }

    p {
      margin-bottom: 0px !important;

      span {
        font-size: 8px;
        width: 8px;
        display: inline-block;
        position: relative;

        svg {
          position: absolute;
          top: 9px;
        }
      }
    }
  }

  &__show_more {
    font-family: "Montserrat-Medium";
    text-transform: uppercase;
    color: $color-purple;
    text-decoration: underline;
    font-size: 11px;
    line-height: 28px;
    cursor: pointer;
  }
}

@media (min-width: $device-width--md) {
  .features {
    margin-bottom: 35px;
  }
}

@media (min-width: $device-width--xlg) {
  .features {
    margin-bottom: 65px;

    &__content {
      display: list-item;
      column-count: 2;
      list-style: none;

      p span svg {
        top: 13px;
      }
    }
  }
}

@media (min-width: $device-width--xl) {
  .features {
    margin-bottom: 65px;
  }
}
