@import "../../assets/styles/shared.scss";

.page {
  padding-left: $padding-left;
  padding-right: $padding-right;
  margin-top: 30px;

  h1 {
    font-family: "Montserrat-SemiBold";
    margin-top: 0px;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
  }

  p {
    font-family: "LibreCaslonText-Regular";
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 32px;
  }

  @media (min-width: $device-width--md) {
    padding-left: $padding-left--md;
    padding-right: $padding-right--md;
    margin-top: 60px;

    h1 {
      text-align: left;
    }
  }

  @media (min-width: $device-width--lg) {
    padding-left: $padding-left--lg;
    padding-right: $padding-right--lg;
  }

  @media (min-width: $device-width--xlg) {
    padding-left: $padding-left--xlg;
    padding-right: $padding-right--xlg;
    margin-top: 65px;

    p {
      font-size: 18px;
      line-height: 36px;
    }
  }

  @media (min-width: $device-width--xl) {
    padding-left: $padding-left--xl;
    padding-right: $padding-right--xl;
    margin-top: 70px;

    p {
      font-size: 19px;
      line-height: 38px;
    }
  }

  @media (min-width: $device-width--xxl) {
    padding-left: $padding-left--xxl;
    padding-right: $padding-right--xxl;
    margin-top: 75px;

    p {
      font-size: 20px;
      line-height: 40px;
    }
  }
}

.privacy {
  margin-bottom: 50px;

  h1 {
    margin-bottom: 37px;
  }

  @media (min-width: $device-width--md) {
    margin-bottom: 70px;

    h1 {
      margin-bottom: 40px;
    }
  }

  @media (min-width: $device-width--md) {
    margin-bottom: 85px;
  }

  @media (min-width: $device-width--xlg) {
    margin-bottom: 80px;

    h1 {
      margin-bottom: 30px;
    }
  }

  @media (min-width: $device-width--xl) {
    margin-bottom: 60px;

    h1 {
      margin-bottom: 25px;
    }
  }

  @media (min-width: $device-width--xxl) {
    margin-bottom: 90px;

    h1 {
      margin-bottom: 20px;
    }
  }
}

.cookies {
  margin-bottom: 50px;

  h1 {
    margin-bottom: 48px;
  }

  @media (min-width: $device-width--md) {
    margin-bottom: 115px;

    h1 {
      margin-bottom: 40px;
    }
  }

  @media (min-width: $device-width--md) {
    margin-bottom: 140px;
  }

  @media (min-width: $device-width--xlg) {
    margin-bottom: 115px;

    h1 {
      margin-bottom: 30px;
    }
  }

  @media (min-width: $device-width--xl) {
    margin-bottom: 140px;

    h1 {
      margin-bottom: 25px;
    }
  }

  @media (min-width: $device-width--xxl) {
    h1 {
      margin-bottom: 20px;
    }
  }
}
