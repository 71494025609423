@import "../../assets/styles/shared.scss";

.carousel {
  div {
    height: 220px;
    overflow: hidden;
  }

  &__item {
    &:focus-visible {
      outline: none;
    }

    img {
      max-width: 100vw;
      width: 100vw;
      height: auto;
      cursor: pointer;
    }
  }

  &__arrow {
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    z-index: 10;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: white;
    border: none;
    outline: none;
    background: transparent;

    &__prev {
      left: $padding-left/2 - (20 * 0.375);
    }

    &__next {
      right: $padding-right/2 - (20 * 0.375);
    }
  }
}

@media (min-width: $device-width--md) {
  .carousel {
    div {
      height: 360px;
    }

    &__item img {
      width: auto;
      height: 100%;
      margin-right: 4px;
    }

    &__arrow {
      font-size: 30px;
      line-height: 30px;
      height: 30px;

      &__prev {
        left: $padding-left--md - (30 * 0.375);
      }

      &__next {
        right: $padding-right--md - (30 * 0.375);
      }
    }
  }
}

@media (min-width: $device-width--lg) {
  .carousel {
    div {
      height: 400px;
    }

    &__item img {
      margin-right: 7px;
    }

    &__arrow {
      font-size: 32px;
      line-height: 32px;
      height: 32px;

      &__prev {
        left: $padding-left--lg - (32 * 0.375);
      }

      &__next {
        right: $padding-right--lg - (32 * 0.375);
      }
    }
  }
}

@media (min-width: $device-width--xlg) {
  .carousel {
    div {
      height: 805px;
    }

    &__item img {
      margin-right: 17px;
    }

    &__arrow {
      font-size: 64px;
      line-height: 64px;
      height: 64px;

      &__prev {
        left: $padding-left--xlg - (64 * 0.375);
      }

      &__next {
        right: $padding-right--xlg - (64 * 0.375);
      }
    }
  }
}

@media (min-width: $device-width--xl) {
  .carousel__arrow {
    &__prev {
      left: $padding-left--xl - (64 * 0.375);
    }

    &__next {
      right: $padding-right--xl - (64 * 0.375);
    }
  }
}

@media (min-width: $device-width--xxl) {
  .carousel__arrow {
    &__prev {
      left: $padding-left--xxl - 30 - (64 * 0.375);
    }

    &__next {
      right: $padding-right--xxl - 30 - (64 * 0.375);
    }
  }
}
