@font-face {
  font-family: "Montserrat-Light";
  src: url(../fonts/Montserrat/Montserrat-Light.ttf);
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url(../fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Montserrat-Italic";
  src: url(../fonts/Montserrat/Montserrat-Italic.ttf);
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url(../fonts/Montserrat/Montserrat-Medium.ttf);
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url(../fonts/Montserrat/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(../fonts/Montserrat/Montserrat-Bold.ttf);
}

@font-face {
  font-family: "LibreCaslonText-Regular";
  src: url(../fonts/LibreCaslonText/LibreCaslonText-Regular.ttf);
}

@font-face {
  font-family: "LibreCaslonText-Bold";
  src: url(../fonts/LibreCaslonText/LibreCaslonText-Bold.ttf);
}
