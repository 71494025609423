@import "./fonts.scss";

$device-width--md: 768px;
$device-width--lg: 1024px;
$device-width--xlg: 1240px;
$device-width--xl: 1400px;
$device-width--xxl: 1920px;

$padding-left: 15px;
$padding-right: 15px;

$padding-left--md: 38px;
$padding-right--md: 38px;

$padding-left--lg: 38px;
$padding-right--lg: 38px;

$padding-left--xlg: 60px;
$padding-right--xlg: 60px;

$padding-left--xl: 80px;
$padding-right--xl: 80px;

$padding-left--xxl: 130px;
$padding-right--xxl: 130px;

$color-purple: #5252b2;

$border-color--gray: #707070;
