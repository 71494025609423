@import "../../assets/styles/shared.scss";

.agent {
  &__content {
    &__col {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__second {
        h4 {
          position: relative;
          display: flex;
          flex-direction: column;
          width: fit-content;
          font-family: "Montserrat-SemiBold";
          font-size: 18px;
          line-height: 22px;
          margin-top: 0;
          margin-bottom: 15px;

          &::after {
            content: "";
            height: 2px;
            background-color: $color-purple;
          }
        }

        p {
          margin-bottom: 0px;
        }
      }
    }

    &__photo {
      width: 85px;
      border-radius: 50%;
      overflow: hidden;
      margin: 20px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-purple;
      color: white;
      text-transform: uppercase;
      font-family: "Montserrat-SemiBold";
      font-size: 16px;
      line-height: 19px;
      padding: 16px;
      border: 2px solid $color-purple;
      box-sizing: border-box;
      margin-top: 45px;
      margin-left: -$padding-left;
      margin-right: -$padding-right;
      width: -webkit-fill-available;
      text-align: center;
      cursor: pointer;
    }
  }
}

@media (min-width: $device-width--md) {
  .agent {
    &__content {
      &__col {
        &__second {
          h4 {
            font-family: "Montserrat-Regular";
            font-weight: normal;
            text-transform: uppercase;

            &::after {
              display: none;
            }
          }

          p {
            margin-bottom: 0 !important;
          }
        }
      }

      &__photo {
        width: 160px;
      }

      &__button {
        font-size: 18px;
        line-height: 22px;
        margin-left: -$padding-left--md;
        margin-right: -$padding-right--md;
        padding: 20px;
      }
    }
  }
}

@media (min-width: $device-width--lg) {
  .agent {
    margin-bottom: 105px;

    &__content {
      display: flex;
      flex-wrap: wrap;
      max-width: 345px;

      &__col {
        &__second {
          align-items: flex-start;

          h4 {
            font-size: 22px;
            line-height: 37px;
            margin-bottom: 0px;
          }

          p {
            font-size: 16px !important;
            line-height: 21px !important;

            &:first-of-type {
              margin-bottom: 18px !important;
            }
          }
        }

        &__third {
          width: 100%;
        }
      }

      &__photo {
        width: 125px;
        margin: 0px;
        margin-left: -30px;
      }

      &__button {
        margin-left: 0;
        margin-right: 0;
        font-size: 16px;
        line-height: 19px;
        border-radius: 10px;
        padding: 11px;
        margin-top: 25px;
      }
    }
  }
}

@media (min-width: $device-width--xlg) {
  .agent {
    margin-bottom: 33px;

    &__content {
      max-width: 445px;

      &__col {
        &__second {
          h4 {
            font-size: 22px;
            line-height: 24px;
          }

          p :first-of-type {
            margin-bottom: 21px !important;
          }
        }
      }

      &__photo {
        width: 106px;
        margin: 0px;
        margin-left: -10px;
      }

      &__button {
        padding: 20px;
        border-radius: 20px;
        font-size: 18px;
        line-height: 21px;
        margin-top: 15px;
      }
    }
  }
}

@media (min-width: $device-width--xl) {
  .agent {
    margin-bottom: 65px;

    &__content {
      max-width: 455px;

      &__photo {
        margin-left: 0px;
      }

      &__button {
        margin-top: 30px;
      }

      &__col {
        &__second {
          h4 {
            line-height: 55px;
          }

          p {
            font-size: 18px !important;
            line-height: 24px !important;

            &:first-of-type {
              margin-bottom: 32px !important;
            }
          }
        }
      }

      &__photo {
        width: 160px;
      }

      &__button {
        margin-top: 25px;
      }
    }
  }
}

@media (min-width: $device-width--xxl) {
  .agent {
    &__content {
      max-width: 600px;

      &__col {
        &__first {
          margin-right: 35px;
        }

        &__second {
          h4 {
            font-size: 33px;
          }

          p {
            font-size: 20px !important;
            line-height: 24px !important;
          }
        }
      }

      &__photo {
        width: 255px;
      }

      &__button {
        font-size: 18px;
        margin-top: 35px;
      }
    }
  }
}
