@import "../../assets/styles/shared.scss";

.header {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__menu {
    position: absolute;
    left: $padding-left;
    font-size: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__logo {
    height: 50%;
    cursor: pointer;

    img {
      height: 100%;
    }
  }

  &__address {
    display: none;
  }

  &__button {
    font-family: "Montserrat-SemiBold";
    position: absolute;
    cursor: pointer;

    &_tour {
      right: $padding-right;
      background-color: $color-purple;
      color: white;
      border-radius: 8px;
      padding: 5px 10px;
      line-height: 15px;
      text-transform: uppercase;
      font-size: 12px;
    }

    &_request {
      display: none;
    }
  }
}

@media (min-width: $device-width--md) {
  .header {
    height: 65px;

    &__menu {
      left: $padding-left--md;
      font-size: 24px;
    }

    &__button_tour {
      right: $padding-right--md;
      padding: 10px 30px;
    }
  }
}

@media (min-width: $device-width--lg) {
  .header {
    &__menu,
    &__button_tour {
      display: none;
    }

    &__address {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: $padding-left--lg;

      h5 {
        font-family: "Montserrat-SemiBold";
        margin: 0;
        font-size: 22px;
        line-height: 27px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        margin: 0;
      }
    }

    &__logo {
      height: 40px;
    }

    &__button_request {
      display: block;
      font-size: 16px;
      color: $color-purple;
      padding: 10px 20px 9px;
      right: $padding-right--lg;
      border-radius: 10px;
      border: 1px solid $color-purple;
      text-transform: uppercase;
    }
  }
}

@media (min-width: $device-width--xlg) {
  .header {
    height: 96px;

    &__address {
      left: $padding-left--xlg;

      h5 {
        font-size: 26px;
        line-height: 32px;
      }

      p {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__logo {
      height: 55px;
    }

    &__button_request {
      right: $padding-right--xlg;
      font-size: 18px;
      padding: 16px 50px 15px;
    }
  }
}

@media (min-width: $device-width--xl) {
  .header {
    &__address {
      left: $padding-left--xl;
    }

    &__button_request {
      right: $padding-right--xl;
    }
  }
}

@media (min-width: $device-width--xxl) {
  .header {
    &__address {
      left: $padding-left--xxl;

      h5 {
        font-size: 36px;
        line-height: 44px;
      }

      p {
        font-size: 22px;
        line-height: 27px;
      }
    }

    &__button_request {
      right: $padding-right--xxl;
    }
  }
}
