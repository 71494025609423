@import "../../../assets/styles/shared.scss";

.modal {
  & > div > div {
    height: fit-content;
    max-width: calc(100vw - 40px);
    width: 600px;
    inset: 20px !important;

    @media (min-width: $device-width--md) {
      inset: calc(5vh - 20px) !important;
      left: calc(50% - 300px) !important;
    }
  }
}

.carousel {
  div {
    height: 90vh;
    overflow: hidden;
  }

  &__item {
    &:focus-visible {
      outline: none;
    }

    img {
      max-width: 100vw;
      height: 100%;
      margin: auto;
    }
  }

  &__arrow {
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    z-index: 10;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #333;
    border: none;
    outline: none;
    background: transparent;

    &__prev {
      left: $padding-left/2 - (20 * 0.375);
    }

    &__next {
      right: $padding-right/2 - (20 * 0.375);
    }
  }
}

@media (min-width: $device-width--md) {
  .carousel {
    &__arrow {
      font-size: 30px;
      line-height: 30px;
      height: 30px;
    }
  }
}

@media (min-width: $device-width--lg) {
  .carousel {
    &__arrow {
      font-size: 32px;
      line-height: 32px;
      height: 32px;
    }
  }
}

@media (min-width: $device-width--xlg) {
  .carousel {
    &__arrow {
      font-size: 64px;
      line-height: 64px;
      height: 64px;
    }
  }
}
