@import "../../../assets/styles/shared.scss";

.modal {
  & > div > div[role="dialog"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 150px;
    inset: unset !important;
    left: calc(50% - 170px) !important;
    top: calc(50% - 55px) !important;
    overflow: inherit !important;

    @media (min-width: $device-width--md) {
      width: 400px;
      height: 250px;
      left: calc(50% - 200px) !important;
      top: calc(50% - 125px) !important;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    font-family: "LibreCaslonText-Regular";
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
  }
}
