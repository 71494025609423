@import "../../../assets/styles/shared.scss";

.modal {
  & > div > div {
    width: 100vw;
    height: 100vw;
    left: 0 !important;
    top: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    border: none !important;
  }
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 36px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
