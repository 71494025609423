@import "../../assets/styles/shared.scss";

.listing__content {
  padding-left: $padding-left;
  padding-right: $padding-right;

  &__col {
    h3 {
      position: relative;
      display: flex;
      flex-direction: column;
      width: fit-content;
      font-family: "Montserrat-SemiBold";
      font-size: 18px;
      line-height: 22px;
      margin-top: 0;
      margin-bottom: 15px;

      &::after {
        content: "";
        height: 2px;
        background-color: $color-purple;
      }
    }

    p {
      margin-top: 0px;
      font-size: 16px;
      line-height: 32px;
    }

    &__third {
      width: 100%;
    }
  }
}

@media (min-width: $device-width--md) {
  .listing__content {
    padding-left: $padding-left--md;
    padding-right: $padding-right--md;

    &__col {
      h3 {
        font-size: 22px;
        line-height: 27px;
      }

      p {
        font-size: 18px;
        line-height: 36px;
        margin-bottom: 2rem;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media (min-width: $device-width--lg) {
  .listing__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: $padding-left--lg;
    padding-right: $padding-right--lg;

    &__col {
      &__first {
        max-width: 550px;
      }
    }
  }
}

@media (min-width: $device-width--xlg) {
  .listing__content {
    padding-left: $padding-left--xlg;
    padding-right: $padding-right--xlg;

    &__col {
      &__first {
        max-width: calc(100% - 395px);
      }

      &__second {
        width: 354px;
      }

      &__third {
        display: none;
      }
    }
  }
}

@media (min-width: $device-width--xl) {
  .listing__content {
    padding-left: $padding-left--xl;
    padding-right: $padding-right--xl;

    &__col {
      &__first {
        max-width: calc(100% - 535px);
      }

      &__second {
        width: 455px;
      }
    }
  }
}

@media (min-width: $device-width--xxl) {
  .listing__content {
    padding-left: $padding-left--xxl;
    padding-right: $padding-right--xxl;

    &__col {
      &__first {
        max-width: 955px;
      }

      &__second {
        width: 600px;
      }
    }
  }
}
