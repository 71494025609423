@import "./fonts.scss";
@import "./shared.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

body {
  margin: 0;
  font-family: "LibreCaslonText-Regular";

  &.ReactModal__Body--open {
    // max-width: 100vw;
    // max-height: 100vh;
    // overflow: hidden;
  }
}

div {
  box-sizing: border-box;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

@media (min-width: $device-width--md) {
  .d-none--md {
    display: none !important;
  }

  .d-block--md {
    display: block !important;
  }

  .d-flex--md {
    display: flex !important;
  }
}

@media (min-width: $device-width--lg) {
  .d-none--lg {
    display: none !important;
  }

  .d-block--lg {
    display: block !important;
  }

  .d-flex--lg {
    display: flex !important;
  }
}

@media (min-width: $device-width--xlg) {
  .d-none--xlg {
    display: none !important;
  }

  .d-block--xlg {
    display: block !important;
  }
}

@media (min-width: $device-width--xxl) {
  .d-none--xxl {
    display: none !important;
  }

  .d-block--xxl {
    display: block !important;
  }
}

.ReactModal__Overlay {
  .image-gallery-slide {
    height: 100vh;
  }

  .image-gallery-icon {
    &.image-gallery-fullscreen-button {
      bottom: 120px;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      color: $color-purple;
    }
  }

  .image-gallery-thumbnails-wrapper.bottom {
    margin-top: -95px;
    transition: all ease-in-out 0.2s;
  }

  .fullscreen {
    .image-gallery-image {
      max-height: inherit;
    }

    .image-gallery-thumbnails-wrapper.bottom {
      margin-top: 0px;
    }
  }
}
