@import "../../assets/styles/shared.scss";

.description {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &__content {
    overflow-y: hidden;
    transition: all 0.3s;

    &__opened {
      max-height: 1000px;
    }

    &__collapsed {
      max-height: 245px;
    }
  }

  &__show_more {
    font-family: "Montserrat-Medium";
    text-transform: uppercase;
    color: $color-purple;
    text-decoration: underline;
    font-size: 11px;
    line-height: 28px;
  }
}

@media (min-width: $device-width--md) {
  .description {
    margin-bottom: 35px;
  }
}

@media (min-width: $device-width--xlg) {
  .description {
    margin-bottom: 33px;
  }
}

@media (min-width: $device-width--xl) {
  .description {
    margin-bottom: 55px;
  }
}

@media (min-width: $device-width--xxl) {
  .description {
    margin-bottom: 65px;
  }
}
