@import "../../assets/styles/shared.scss";

.details__content {
  margin-bottom: 30px;

  p span {
    font-family: "Montserrat-SemiBold";
  }
}

@media (min-width: $device-width--md) {
  .details__content {
    display: list-item;
    column-count: 2;
    list-style: none;
    width: 345px;
    margin-bottom: 35px;

    p {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem !important;
    }
  }
}

@media (min-width: $device-width--xlg) {
  .details__content {
    width: 645px;
    margin-bottom: 33px;

    p {
      margin-bottom: 2rem !important;

      &:nth-child(3n) {
        margin-bottom: 0px !important;
      }

      span {
        font-size: 22px;
      }
    }
  }
}

@media (min-width: $device-width--xl) {
  .details__content {
    width: 700px;
    margin-bottom: 55px;

    p {
      font-size: 18px;
    }
  }
}

@media (min-width: $device-width--xxl) {
  .details__content {
    width: 955px;
    margin-bottom: 65px;
  }
}
