@import "../../assets/styles/shared.scss";

.video {
  margin-bottom: 30px;

  &__content {
    margin-left: -$padding-left;
    margin-right: -$padding-right;
    height: 210px;
  }
}

@media (min-width: $device-width--md) {
  .video {
    margin-bottom: 35px;

    &__content {
      margin-left: -$padding-left--md;
      margin-right: -$padding-right--md;
      height: 430px;
    }
  }
}

@media (min-width: $device-width--lg) {
  .video {
    margin-bottom: 0px;

    &__content {
      margin-left: -$padding-left--lg;
      margin-right: -$padding-right--lg;
      height: 575px;
      background-color: #464646;
    }
  }
}

@media (min-width: $device-width--xlg) {
  .video {
    &__content {
      margin-left: 0px;
      margin-right: 0px;
      height: 198px;
    }
  }
}

@media (min-width: $device-width--xl) {
  .video {
    &__content {
      height: 255px;
    }
  }
}

@media (min-width: $device-width--xxl) {
  .video {
    &__content {
      height: 340px;
    }
  }
}
