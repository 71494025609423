@import "../../assets/styles/shared.scss";

$padding-left: 30px;
$padding-right: 30px;

$padding-left--md: 40px;
$padding-right--md: 40px;

$padding-left--lg: 40px;
$padding-right--lg: 40px;

$padding-left--xlg: 40px;
$padding-right--xlg: 40px;

$padding-left--xl: 80px;
$padding-right--xl: 80px;

$padding-left--xxl: 120px;
$padding-right--xxl: 120px;

.page_home {
  max-width: $device-width--xxl;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 95px;
  padding-left: 10px;
  padding-right: 10px;

  button {
    border: 1px solid #000000;
    border-radius: 13px;
    font-size: 13px;
    line-height: 16px;
    font-family: "Montserrat-Regular";
    height: 46px;
    width: 135px;
    background-color: #fff;
  }

  @media (min-width: $device-width--md) {
    display: none;
  }
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 175px;
  height: 46px;
  cursor: pointer;

  img {
    height: 100%;
    margin-right: 5px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    span:first-child {
      font-family: "Montserrat-Light";
      font-size: 18px;
      line-height: 22px;
    }

    span:last-child {
      font-family: "LibreCaslonText-Regular";
      font-size: 11px;
      line-height: 14px;
    }
  }

  @media (min-width: $device-width--md) {
    max-width: 240px;

    img {
      margin-right: 20px;
    }

    &__text {
      span:first-child {
        font-size: 26px;
        line-height: 32px;
      }
    }
  }

  @media (min-width: $device-width--xlg) {
    max-width: 245px;

    &__text {
      span:first-child {
        font-family: "Montserrat-Regular";
      }
    }
  }
}

.hero {
  background-image: url("/assets/images/home/Hero Image.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 485px;
  padding: 28px 18px;

  &__logo {
    display: none;
  }

  &__services {
    h1 {
      font-family: "Montserrat-Bold";
      color: #000;
      margin-top: 0px;
      font-size: 19px;
      line-height: 23px;
      max-width: 165px;
      margin-bottom: 125px;
    }

    p {
      font-family: "LibreCaslonText-Regular";
      color: #717171;
      margin-top: 0px;
      font-size: 14px;
      line-height: 17px;
      max-width: 200px;
    }
  }

  &__button {
    width: 192px;
    height: 44px;
    text-decoration: none;
    border: 1px solid #000000;
    font-family: "Montserrat-Regular";
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    color: #000;

    &:first-child {
      margin-right: 40px;
    }

    &__black {
      background-color: #000;
      color: #fff;
    }

    &__groups {
      display: none;
      position: relative;
    }
  }

  @media (min-width: $device-width--md) {
    padding: 25px 40px;
    background-position: 40%;

    &__logo {
      display: flex;
    }

    &__services {
      margin-top: 100px;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 38px;
        max-width: inherit;
      }

      p {
        font-size: 14px;
        line-height: 32px;
        margin-bottom: 38px;
        max-width: inherit;
      }
    }

    &__button {
      width: 156px;
      height: 44px;
      font-size: 16px;
      line-height: 19px;

      &:first-child {
        margin-right: 12px;
      }

      &__groups {
        display: flex;
      }
    }
  }

  @media (min-width: $device-width--lg) {
    padding: 25px 210px;
    background-position: left;
  }

  @media (min-width: $device-width--xlg) {
    background-position: center;
  }

  @media (min-width: $device-width--xl) {
    height: 550px;
    padding: 25px 240px;

    &__services {
      margin-top: 120px;

      h1 {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 48px;
      }

      p {
        font-size: 16px;
        line-height: 36px;
        margin-bottom: 46px;
      }
    }

    &__button {
      width: 175px;
      height: 44px;
      font-size: 18px;
      line-height: 22px;

      &:first-child {
        margin-right: 25px;
      }
    }
  }

  @media (min-width: $device-width--xxl) {
    height: 725px;
    padding: 40px 330px;

    &__services {
      margin-top: 165px;

      h1 {
        font-size: 38px;
        line-height: 47px;
        margin-bottom: 36px;
      }

      p {
        font-size: 20px;
        line-height: 44px;
        margin-bottom: 60px;
      }
    }

    &__button {
      width: 192px;
      height: 44px;
      font-size: 20px;
      line-height: 24px;

      &:first-child {
        margin-right: 40px;
      }
    }
  }
}

.button {
  flex-basis: 0;
  flex-grow: 1;
  font-family: "Montserrat-Regular";
  font-size: 18px;
  line-height: 22px;
  border: 3px solid #000000;
  color: #000000;
  text-decoration: none;
  box-sizing: border-box;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__black {
    background-color: #000000;
    color: #fff;
  }

  &__group {
    display: flex;

    @media (min-width: $device-width--md) {
      display: none;
    }
  }
}

.SBR {
  position: relative;
  padding-left: $padding-left;
  padding-right: $padding-right;

  &::before {
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &::after {
    position: absolute;
    font-family: "Montserrat-Regular";
    font-size: 19px;
    line-height: 23px;
  }

  p {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
  }

  &__selling {
    padding-top: 265px;
    margin-bottom: 100px;

    &::before {
      background-image: url("/assets/images/home/Selling Hands.png");
      width: 250px;
      height: 105px;
      top: 105px;
      left: 60px;
    }

    &::after {
      content: "Selling";
      top: 90px;
      left: 250px;
    }
  }

  &__buying {
    padding-top: 260px;
    margin-bottom: 100px;

    &::before {
      background-image: url("/assets/images/home/Buying Hands.png");
      width: 215px;
      height: 165px;
      top: 40px;
      left: 70px;
    }

    &::after {
      content: "Buying";
      top: 100px;
      left: 235px;
    }
  }

  &__renting {
    padding-top: 295px;
    margin-bottom: 140px;

    &::before {
      background-image: url("/assets/images/home/Renting Hands.png");
      width: 180px;
      height: 200px;
      top: 40px;
      left: 80px;
    }

    &::after {
      content: "Renting";
      top: 95px;
      left: 220px;
    }
  }

  @media (min-width: $device-width--md) {
    padding-left: $padding-left--md;
    padding-right: $padding-right--md;

    &::after {
      font-size: 26px;
      line-height: 32px;
    }

    p {
      text-align: left;
      line-height: 27px;
    }

    &__selling {
      padding-top: 255px;

      &::before {
        top: 85px;
        left: 260px;
      }

      &::after {
        left: 420px;
        top: 60px;
      }
    }

    &__buying {
      padding-top: 290px;

      &::before {
        top: 65px;
        left: 270px;
      }

      &::after {
        left: 435px;
        top: 115px;
      }
    }

    &__renting {
      padding-top: 325px;
      margin-bottom: 100px;

      &::before {
        top: 65px;
        left: 270px;
      }

      &::after {
        top: 115px;
        left: 425px;
      }
    }
  }

  @media (min-width: $device-width--lg) {
    padding-top: 0px;
    padding-left: 380px;
    padding-right: $padding-right--lg;

    &::before {
      left: 45px;
    }

    &__selling {
      margin-top: 100px;

      &::before {
        top: 38px;
      }

      &::after {
        left: 200px;
        top: 15px;
      }
    }

    &__buying {
      margin-top: 60px;

      &::before {
        top: -5px;
      }

      &::after {
        left: 205px;
        top: 40px;
      }
    }

    &__renting {
      margin-top: 95px;
      margin-bottom: 170px;

      &::before {
        top: -35px;
      }

      &::after {
        top: 15px;
        left: 195px;
      }
    }
  }

  @media (min-width: $device-width--xlg) {
    padding-right: 65px;

    &::before {
      left: 60px;
    }

    p {
      font-size: 19px;
      line-height: 33px;
    }

    &__selling {
      margin-top: 150px;

      &::before {
        top: 45px;
      }

      &::after {
        left: 220px;
        top: 20px;
      }
    }

    &__buying {
      margin-top: 105px;

      &::before {
        top: 10px;
      }

      &::after {
        left: 225px;
        top: 60px;
      }
    }

    &__renting {
      margin-top: 110px;
      margin-bottom: 205px;

      &::before {
        top: -15px;
      }

      &::after {
        top: 35px;
        left: 215px;
      }
    }
  }

  @media (min-width: $device-width--xl) {
    padding-right: 80px;
    padding-left: 425px;

    &::before {
      left: 80px;
    }

    &__selling {
      &::before {
        top: 35px;
      }

      &::after {
        left: 240px;
        top: 15px;
      }
    }

    &__buying {
      &::before {
        top: -20px;
      }

      &::after {
        left: 255px;
        top: 30px;
      }
    }

    &__renting {
      margin-top: 130px;
      margin-bottom: 185px;

      &::before {
        width: 180px;
        height: 200px;
        top: -20px;
      }

      &::after {
        top: 25px;
        left: 245px;
      }
    }
  }

  @media (min-width: $device-width--xxl) {
    padding-right: 120px;
    padding-left: 770px;

    &::before {
      left: 120px;
    }

    &::after {
      font-size: 38px;
      line-height: 47px;
    }

    p {
      font-size: 20px;
      line-height: 40px;
    }

    &__selling {
      margin-bottom: 150px;

      &::before {
        left: 120px;
        width: 435px;
        height: 185px;
        top: -5px;
      }

      &::after {
        left: 435px;
        top: -35px;
      }
    }

    &__buying {
      margin-top: 160px;
      margin-bottom: 150px;

      &::before {
        width: 370px;
        height: 285px;
        top: -70px;
      }

      &::after {
        left: 435px;
        top: 35px;
      }
    }

    &__renting {
      margin-top: 230px;
      margin-bottom: 180px;

      &::before {
        width: 330px;
        height: 360px;
        top: -110px;
      }

      &::after {
        top: -20px;
        left: 435px;
      }
    }
  }
}

.where_we_play {
  height: 405px;
  background-image: url("/assets/images/home/Where we play bg--mobile.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 75px $padding-right 55px $padding-left;

  h1 {
    font-family: "Montserrat-Bold";
    text-align: center;
    margin-top: 0px;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 25px;
  }

  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }

  @media (min-width: $device-width--md) {
    height: 340px;
    background-image: url("/assets/images/home/Where we play bg.png");
    padding: 85px $padding-right 85px $padding-left;

    h1 {
      font-family: "Montserrat-Medium";
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 40px;
    }

    p {
      line-height: 27px;
      text-align: left;
    }
  }

  @media (min-width: $device-width--lg) {
  }

  @media (min-width: $device-width--xlg) {
    height: 405px;
    padding: 85px 90px 140px;

    h1 {
      font-size: 34px;
      line-height: 42px;
      margin-bottom: 50px;
    }

    p {
      font-size: 19px;
      line-height: 33px;
    }
  }

  @media (min-width: $device-width--xl) {
    padding: 115px 190px 120px;

    h1 {
      margin-bottom: 45px;
    }
  }

  @media (min-width: $device-width--xxl) {
    padding: 105px 420px 105px;

    h1 {
      font-size: 38px;
      line-height: 47px;
      margin-bottom: 40px;
    }

    p {
      font-size: 20px;
      line-height: 40px;
    }
  }
}

.map {
  background-image: url("/assets/images/home/ChicagoMap_New.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 425px;
  margin-top: -3px;

  @media (min-width: $device-width--md) {
    height: 705px;
  }

  @media (min-width: $device-width--lg) {
    height: 870px;
  }

  @media (min-width: $device-width--xlg) {
    height: 875px;
  }

  @media (min-width: $device-width--xl) {
    height: 985px;
  }

  @media (min-width: $device-width--xxl) {
    height: 1080px;
  }
}

.about {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  padding-left: $padding-left;
  padding-right: $padding-right;

  &__image_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;

    img {
      width: 140px;
      margin-bottom: 25px;
    }

    p {
      font-family: "Montserrat-Italic";
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: center;
    }
  }

  &__content_wrapper {
    h1 {
      font-family: "Montserrat-Bold";
      font-size: 18px;
      line-height: 22px;
      margin-top: 0px;
      text-align: center;
      margin-bottom: 40px;
    }

    p {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
    }
  }

  @media (min-width: $device-width--md) {
    padding-left: $padding-left--md;
    padding-right: $padding-right--md;

    &__image_wrapper {
      margin-bottom: 35px;

      img {
        width: 150px;
        margin-bottom: 8px;
      }
    }

    &__content_wrapper {
      h1 {
        font-family: "Montserrat-Medium";
        font-size: 28px;
        line-height: 34px;
      }

      p {
        text-align: left;
        line-height: 27px;
      }
    }
  }

  @media (min-width: $device-width--lg) {
    flex-direction: row;
    margin-top: 120px;
    padding-left: $padding-left--lg;
    padding-right: $padding-right--lg;

    &__image_wrapper {
      margin-right: 60px;
      margin-bottom: 0px;

      img {
        width: 192px;
        margin-bottom: 3px;
      }
    }

    &__content_wrapper {
      h1 {
        text-align: left;
        margin-bottom: 55px;
      }
    }
  }

  @media (min-width: $device-width--xlg) {
    margin-top: 220px;
    padding-left: 90px;
    padding-right: 90px;

    &__image_wrapper {
      margin-right: 100px;

      p {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &__content_wrapper {
      h1 {
        font-size: 38px;
        line-height: 47px;
        margin-bottom: 45px;
      }

      p {
        font-size: 19px;
        line-height: 33px;
      }
    }
  }

  @media (min-width: $device-width--xl) {
    margin-top: 245px;
    padding-left: 145px;
    padding-right: 145px;

    &__image_wrapper {
      margin-right: 150px;
    }
  }

  @media (min-width: $device-width--xxl) {
    margin-top: 235px;
    padding-left: 350px;
    padding-right: 350px;

    &__image_wrapper {
      margin-right: 205px;

      img {
        width: 215px;
        margin-bottom: 15px;
      }
    }

    &__content_wrapper {
      h1 {
        margin-bottom: 60px;
      }

      p {
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
}

.FAQ {
  margin-top: 85px;

  h1 {
    font-family: "Montserrat-Bold";
    margin-top: 0px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 75px;
  }

  h3 {
    font-family: "LibreCaslonText-Bold";
    margin-top: 0px;
    margin-bottom: 30px;
    cursor: pointer;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
  }

  p {
    font-family: "Montserrat-Regular";
    margin-top: 0px;
    margin-bottom: 0px;
    color: #626262;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  &__inner_wrapper {
    display: flex;
    flex-direction: column;
    padding-left: $padding-left;
    padding-right: $padding-right;
  }

  &__col {
    flex-basis: 0;
    flex-grow: 1;
  }

  &__item {
    margin-bottom: 45px;
    transition-duration: 0.2s;

    &__opened {
      margin-bottom: 30px;
    }

    &__desc {
      max-height: 0px;
      overflow-y: hidden;
      transition-duration: 0.2s;

      &__opened {
        max-height: 100vh;
      }
    }
  }

  @media (min-width: $device-width--md) {
    margin-top: 90px;

    h1 {
      font-family: "Montserrat-Medium";
      font-size: 28px;
      line-height: 34px;
    }

    h3 {
      font-size: 18px;
      line-height: 48px;
    }

    p {
      font-size: 16px;
      line-height: 23px;
    }

    &__inner_wrapper {
      padding-left: $padding-left--md;
      padding-right: $padding-right--md;
    }
  }

  @media (min-width: $device-width--lg) {
    margin-top: 120px;

    h1 {
      margin-bottom: 85px;
    }

    h3 {
      text-align: left;
      margin-bottom: 20px;
    }

    p {
      text-align: left;
    }

    &__inner_wrapper {
      padding-left: $padding-left--lg;
      padding-right: $padding-right--lg;
      flex-direction: row;
      justify-content: space-between;
    }

    &__col {
      &:first-child {
        max-width: 485px;
      }

      &:last-child {
        max-width: calc(50% - 35px);
      }
    }

    &__item {
      margin-bottom: 65px;

      &__opened {
        margin-bottom: 25px;
      }
    }
  }

  @media (min-width: $device-width--xlg) {
    margin-top: 210px;

    h1 {
      font-size: 38px;
      line-height: 47px;
      margin-bottom: 90px;
    }

    h3 {
      font-size: 22px;
    }

    &__inner_wrapper {
      padding-left: $padding-left--xlg;
      padding-right: $padding-right--xlg;
    }

    &__col {
      &:first-child {
        max-width: 615px;
      }
    }
  }

  @media (min-width: $device-width--xl) {
    margin-top: 235px;

    h1 {
      margin-bottom: 95px;
    }

    h3 {
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;
    }

    &__inner_wrapper {
      padding-left: $padding-left--xl;
      padding-right: $padding-right--xl;
    }

    &__col {
      &:last-child {
        max-width: calc(50% - 115px);
      }
    }
  }

  @media (min-width: $device-width--xxl) {
    margin-top: 225px;

    h1 {
      margin-bottom: 140px;
    }

    h3 {
      font-size: 28px;
      line-height: 48px;
    }

    &__inner_wrapper {
      padding-left: $padding-left--xxl;
      padding-right: $padding-right--xxl;
    }

    &__col {
      &:first-child {
        max-width: 780px;
      }

      &:last-child {
        max-width: calc(50% - 180px);
      }
    }

    &__item {
      margin-bottom: 100px;

      &__opened {
        margin-bottom: 25px;
      }
    }
  }
}

.get_in_touch {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 105px;

  &__col {
    &:first-child {
      margin-top: 15px;
      position: relative;
      padding-left: $padding-left;
      padding-right: $padding-right;
      display: flex;
      flex-direction: column-reverse;

      &::after {
        content: "Message Us Here";
        font-family: "Montserrat-Light";
        text-align: center;
        font-size: 18px;
        line-height: 22px;
        margin: 40px 0px;
      }
    }

    button {
      background-color: #000;
      color: #fff;
      text-transform: capitalize;
      font-family: "Montserrat-Regular";
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      cursor: pointer;
      margin-left: -$padding-left;
      margin-right: -$padding-right;
      font-size: 18px;
      line-height: 22px;
      height: 70px;
    }

    iframe {
      width: 100%;
      height: 435px;
      border: none;
    }

    p {
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 45px;
    }
  }

  @media (min-width: $device-width--md) {
    margin-bottom: 95px;

    &__col {
      &:first-child {
        margin-top: 30px;
        padding-left: $padding-left--md;
        padding-right: $padding-right--md;

        &::after {
          font-size: 28px;
          line-height: 34px;
        }
      }

      button {
        margin-left: -$padding-left--md;
        margin-right: -$padding-right--md;
        font-size: 28px;
        line-height: 34px;
        height: 80px;
      }

      iframe {
        height: 525px;
      }

      p {
        margin-bottom: 70px;
      }
    }
  }

  @media (min-width: $device-width--lg) {
    margin-bottom: 90px;

    &__col {
      &:first-child {
        margin-top: 55px;
        padding-left: 120px;
        padding-right: 120px;
      }

      button {
        margin-left: -120px;
        margin-right: -120px;
      }

      p {
        margin-bottom: 95px;
      }
    }
  }

  @media (min-width: $device-width--xlg) {
    flex-direction: row;
    padding-left: $padding-left--xlg;
    padding-right: $padding-right--xlg;
    margin-bottom: 0px;

    &__col {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 50%;

      &:first-child {
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 30px;
        flex-direction: column;

        &::after {
          display: none;
        }
      }

      button {
        width: 340px;
        height: 80px;
        font-size: 33px;
        line-height: 40px;
        border-radius: 8px;
        margin: 60px auto 85px;
      }

      p {
        font-size: 19px;
        line-height: 33px;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }

  @media (min-width: $device-width--xl) {
    padding-left: $padding-left--xl;
    padding-right: $padding-right--xl;

    &__col {
      &:first-child {
        padding-right: 40px;
      }

      button {
        margin: 65px auto 95px;
      }

      iframe {
        height: 490px;
      }
    }
  }

  @media (min-width: $device-width--xxl) {
    padding-left: $padding-left--xxl;
    padding-right: $padding-right--xxl;

    &__col {
      &:first-child {
        padding-right: 100px;
      }

      button {
        margin: 75px auto 95px;
      }

      p {
        font-size: 20px;
        line-height: 40px;
      }

      iframe {
        height: 580px;
      }
    }
  }
}

.contact {
  position: absolute;
  left: 200px;
  top: -70px;

  &__wrapper {
    position: relative;
    background-image: url("/assets/images/home/City Bottom.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 215px;

    & > span {
      position: absolute;
      font-family: "LibreCaslonText-Bold";
      font-size: 16px;
      line-height: 26px;
      left: 30px;
      top: -40px;
    }
  }

  p {
    font-family: "Montserrat-Light";
    text-align: center;
    margin-top: 0px;
    font-size: 12px;
    line-height: 24px;

    span {
      font-family: "LibreCaslonText-Regular";
      display: none;
    }

    a {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
  }

  @media (min-width: $device-width--md) {
    left: 415px;
    top: -20px;

    &__wrapper {
      height: 435px;

      & > span {
        display: none;
      }
    }

    p {
      font-size: 14px;
      line-height: 28px;

      span {
        display: block;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  @media (min-width: $device-width--lg) {
    left: 605px;
    top: 20px;

    &__wrapper {
      height: 650px;
    }

    p {
      font-size: 16px;
      line-height: 32px;

      span {
        line-height: 32px;
      }
    }
  }

  @media (min-width: $device-width--xlg) {
    left: 750px;
    top: 55px;

    &__wrapper {
      height: 700px;
    }

    p {
      font-family: "Montserrat-Regular";
      font-size: 20px;
      line-height: 44px;

      span {
        font-size: 22px;
      }
    }
  }

  @media (min-width: $device-width--xl) {
    left: 61.5%;
    top: 90px;

    &__wrapper {
      height: 790px;
    }
  }

  @media (min-width: $device-width--xxl) {
    left: 1240px;
    top: 200px;

    &__wrapper {
      height: 1080px;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Montserrat-Regular";
  font-size: 16px;
  line-height: 19px;

  &__col {
    flex-basis: 0;
    flex-grow: 1;
    font-size: 14px;
    line-height: 18px;

    &:first-child {
      text-align: center;

      & > span {
        display: none;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 195px;
        margin-left: auto;
        margin-right: auto;

        a {
          margin-bottom: 0px;
        }
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;

      a {
        &:nth-child(5) {
          display: none;
        }
      }
    }

    a {
      cursor: pointer;
      text-decoration: none;
      color: #000;
      margin-bottom: 10px;

      &:hover {
        color: $color-purple;
      }
    }
  }

  @media (min-width: $device-width--md) {
    padding-left: 115px;
    padding-right: 115px;

    &__col {
      font-size: 16px;
      line-height: 19px;

      &:first-child {
        & > span {
          display: inline-block;
        }

        & > div {
          display: none;
        }
      }

      &:last-child {
        margin-bottom: 35px;

        a {
          &:nth-child(5) {
            display: block;
          }
        }
      }

      a {
        margin-bottom: 0px;
      }
    }
  }

  @media (min-width: $device-width--lg) {
    padding-left: 240px;
    padding-right: 240px;
  }

  @media (min-width: $device-width--xlg) {
    flex-direction: row;
    padding-left: $padding-left--xlg;
    padding-right: $padding-right--xlg;

    &__col {
      &:first-child {
        margin-right: 85px;
      }

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
      }
    }
  }

  @media (min-width: $device-width--xl) {
    padding-left: $padding-left--xl;
    padding-right: $padding-right--xl;

    &__col {
      &:first-child {
        margin-right: 120px;
      }
    }
  }

  @media (min-width: $device-width--xxl) {
    padding-left: $padding-left--xxl;
    padding-right: $padding-right--xxl;

    &__col {
      &:first-child {
        margin-right: 475px;
      }
    }
  }
}
