@import "../../assets/styles/shared.scss";

.summary {
  display: flex;
  flex-direction: column;

  &__col {
    &__first {
      padding-left: $padding-left;
      padding-right: $padding-right;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;
      margin-bottom: 30px;

      h3 {
        font-family: "Montserrat-SemiBold";
        font-size: 16px;
        line-height: 19px;
        margin: 0;
      }

      span {
        font-family: "Montserrat-Regular";
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
      }

      p {
        font-size: 18px;
        line-height: 22px;
        margin: 0;
      }
    }

    &__second {
      display: flex;
      margin-bottom: 25px;
    }
  }

  &__price {
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__bed,
  &__bath,
  &__area {
    display: flex;
  }

  &__address {
    width: 100%;
    margin-top: 12px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 0;
    flex-grow: 1;
    background-color: $color-purple;
    color: white;
    text-transform: uppercase;
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    line-height: 19px;
    padding: 9px;
    border: 2px solid $color-purple;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;

    &__outline {
      background-color: white;
      color: $color-purple;
    }
  }
}

@media (min-width: $device-width--md) {
  .summary {
    flex-direction: row;
    padding-left: $padding-left--md;
    padding-right: $padding-right--md;
    padding-top: 24px;
    padding-bottom: 18px;

    &__col {
      flex-basis: 0;
      flex-grow: 1;
      padding-left: 0px;
      padding-right: 0px;

      &__first,
      &__second {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      &__second {
        justify-content: flex-end;
      }
    }

    &__address {
      margin-top: 8px;
    }

    &__button {
      border: 1px solid $border-color--gray;
      border-radius: 10px;
      background-color: white;
      color: black;
      font-size: 18px;
      line-height: 22px;
      height: 46px;
      max-width: 120px;

      &__outline {
        margin-right: 6px;
        max-width: 175px;
      }
    }
  }
}

@media (min-width: $device-width--lg) {
  .summary {
    padding-left: $padding-left--lg;
    padding-right: $padding-right--lg;
    padding-top: 26px;
    padding-bottom: 14px;

    &__col__first {
      h3,
      span {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__price h3 {
      font-size: 22px;
      line-height: 27px;
    }

    &__address {
      display: none;
    }

    &__button {
      max-width: 145px;

      &__outline {
        max-width: 195px;
      }
    }
  }
}

@media (min-width: $device-width--xlg) {
  .summary {
    padding-left: $padding-left--xlg;
    padding-right: $padding-right--xlg;
    padding-top: 20px;
    padding-bottom: 40px;

    &__col {
      &__first {
        h3 {
          font-size: 33px;
          line-height: 40px;
          text-align: center;
          margin-bottom: 6px;
        }

        span {
          font-size: 17px;
          line-height: 20px;
          text-align: center;
          display: block;
        }
      }

      &__second {
        justify-content: flex-end;
      }
    }

    &__price,
    &__bed,
    &__bath,
    &__area {
      flex-direction: column;
    }

    &__button {
      border-radius: 20px;
      height: 78px;
      max-width: 200px;
      font-size: 30px;
      line-height: 37px;

      &__outline {
        max-width: 295px;
      }
    }
  }
}

@media (min-width: $device-width--xl) {
  .summary {
    padding-left: $padding-left--xl;
    padding-right: $padding-right--xl;
  }
}

@media (min-width: $device-width--xxl) {
  .summary {
    padding-left: $padding-left--xxl;
    padding-right: $padding-right--xxl;
    padding-bottom: 30px;

    &__col__first {
      padding-left: 60px;
      min-width: 955px;
    }

    &__button {
      height: 90px;
      max-width: 235px;

      &__outline {
        max-width: 335px;
        margin-right: 32px;
      }
    }
  }
}
