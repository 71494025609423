@import "../../assets/styles/shared.scss";

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  background-color: black;
  padding-left: $padding-left;
  padding-right: $padding-right;
  font-family: "Montserrat-Regular";

  &__navs {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;

    &__desktop {
      display: none;
    }

    a {
      cursor: pointer;
      color: white;
      text-decoration: none;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  &__copy_right {
    color: white;
    font-size: 12px;
    line-height: 15px;
    text-align: center;

    span {
      display: none;
    }
  }
}

@media (min-width: $device-width--md) {
  .footer {
    height: 155px;
    padding-left: 90px;
    padding-right: 90px;

    &__navs {
      margin-bottom: 30px;

      a {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &__copy_right {
      font-size: 16px;
      line-height: 19px;

      br {
        display: none;
      }

      span {
        display: inline;
      }
    }
  }
}

@media (min-width: $device-width--lg) {
  .footer {
    height: 100px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: $padding-left--lg;
    padding-right: $padding-right--lg;

    &__navs {
      margin-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
      width: 35%;

      &__mobile {
        display: none;
      }

      &__desktop {
        display: flex;
      }
    }
  }
}

@media (min-width: $device-width--xlg) {
  .footer {
    padding-left: $padding-left--xlg;
    padding-right: $padding-right--xlg;

    &__navs {
      margin-bottom: 0px;
      width: 35%;
      padding-left: 0px;
      padding-right: 0px;
    }

    &__navs a,
    &__copy_right {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@media (min-width: $device-width--xl) {
  .footer {
    padding-left: $padding-left--xl;
    padding-right: $padding-right--xl;
  }
}

@media (min-width: $device-width--xxl) {
  .footer {
    padding-left: $padding-left--xxl;
    padding-right: $padding-right--xxl;
  }
}
