@import "../../assets/styles/shared.scss";

.location {
  margin-bottom: 30px;

  &__map {
    margin-left: -$padding-left;
    margin-right: -$padding-right;
    height: 215px;
  }
}

@media (min-width: $device-width--md) {
  .location {
    margin-bottom: 35px;

    &__map {
      margin-left: -$padding-left--md;
      margin-right: -$padding-right--md;
      height: 360px;
    }
  }
}

@media (min-width: $device-width--lg) {
  .location {
    margin-bottom: 35px;

    &__map {
      margin-left: -$padding-left--lg;
      margin-right: -$padding-right--lg;
      height: 410px;
    }
  }
}

@media (min-width: $device-width--xlg) {
  .location {
    margin-bottom: 33px;

    &__map {
      margin-left: 0px;
      margin-right: 0px;
      height: 375px;
    }
  }
}

@media (min-width: $device-width--xl) {
  .location {
    margin-bottom: 55px;

    &__map {
      height: 525px;
    }
  }
}

@media (min-width: $device-width--xxl) {
  .location {
    margin-bottom: 65px;

    &__map {
      height: 600px;
    }
  }
}
