@import "../../../assets/styles/shared.scss";

.modal {
  & > div > div[role="dialog"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 340px;
    height: 110px;
    inset: unset !important;
    left: calc(50% - 170px) !important;
    top: calc(50% - 55px) !important;
    overflow: inherit !important;

    @media (min-width: $device-width--md) {
      width: 400px;
      height: 250px;
      left: calc(50% - 200px) !important;
      top: calc(50% - 125px) !important;
    }
  }

  h2 {
    font-size: 29px;
    line-height: 35px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    font-family: "LibreCaslonText-Regular";
    text-align: center;
    font-weight: 500;

    @media (min-width: $device-width--md) {
      font-size: 47px;
      line-height: 57px;
      margin-top: 29px;
      margin-bottom: 29px;
    }

    a {
      color: #007bff;
      text-decoration: none;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 0;
    font-family: "LibreCaslonText-Regular";
    text-align: center;
    margin-top: 0;

    @media (min-width: $device-width--md) {
      font-size: 29px;
      line-height: 35px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
